"use client";
import * as React from "react";
import { useSignIn } from "@clerk/nextjs";
import { useRouter } from "next/navigation";
import "../globals.scss";

import { Button, TextInput, Checkbox, Tooltip, Layer } from "@carbon/react";
// eslint-disable-next-line import/no-internal-modules
import { Information, ArrowRight } from "@carbon/react/icons";

export default function SignInForm() {
  const { isLoaded, signIn, setActive } = useSignIn();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [incorrectPassword, setIncorrectPassword] = React.useState(false);
  const [showPasswordForm, setShowPasswordForm] = React.useState(false);
  const router = useRouter();
  // Handle the submission of the sign-in form
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }
    if (!showPasswordForm) {
      setShowPasswordForm(true);
      return;
    }
    // Start the sign-in process using the email and password provided
    try {
      const completeSignIn = await signIn.create({
        identifier: email,
        password,
      });
      if (completeSignIn.status !== "complete") {
        // The status can also be `needs_factor_on', 'needs_factor_two', or 'needs_identifier'
        // Please see https://clerk.com/docs/references/react/use-sign-in#result-status for  more information
        console.log(JSON.stringify(completeSignIn, undefined, 2));
      }
      if (completeSignIn.status === "complete") {
        // If complete, user exists and provided password match -- set session active
        await setActive({ session: completeSignIn.createdSessionId });
        // Redirect the user to a post sign-in route
        router.push("/");
      }
    } catch (error: any) {
      // This can return an array of errors.
      // See https://clerk.com/docs/custom-flows/error-handling to learn about error handling
      console.error(JSON.stringify(error, undefined, 2));
      if (error.errors[0].code === "form_password_incorrect") {
        setIncorrectPassword(true);
      }
    }
  };
  // Display a form to capture the user's email and password
  return (
    <div className="w-[475px]">
      <form onSubmit={handleSubmit}>
        <div className="px-5 mb-14">
          <div className="mb-12">
            <h1 className="text-DarkBlue text-3xl pt-4 mb-1 font-semibold">
              Log in to Transacted
            </h1>
            {showPasswordForm ? (
              <span className="text-sm">
                Logging in as {email}{" "}
                <a
                  href="#"
                  onClick={() => setShowPasswordForm(false)}
                  style={{ color: "#0F62FE", textDecoration: "underline" }}
                >
                  Not you?
                </a>
              </span>
            ) : (
              <span className="text-sm">
                Don&apos;t have an account?{" "}
                <a
                  href="/contact-support"
                  style={{ color: "#0F62FE", textDecoration: "underline" }}
                >
                  Contact support
                </a>
              </span>
            )}
          </div>
          <div
            className="border-t"
            style={{ borderTopWidth: "1px", borderTopColor: "#8D8D8D" }}
          >
            {!showPasswordForm && (
              <div className="mb-12">
                <div className="flex justify-between items-center mt-4">
                  <span className="text-xs">Email</span>
                  <a
                    href="#"
                    className="text-xs"
                    style={{ color: "#0F62FE", textDecoration: "underline" }}
                  >
                    Forgot ID?
                  </a>
                </div>
                <Layer>
                  <TextInput
                    size="lg"
                    type="email"
                    labelText=""
                    placeholder="address@transacted.ai"
                    id="email"
                    value={email}
                    name="email"
                    onSubmit={(e) => handleSubmit(e)}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Layer>
                <div className="flex items-center mt-4">
                  <Checkbox
                    labelText={`Remember user ID`}
                    checked={true}
                    id="checkbox-label-1"
                  />
                  <Tooltip align="bottom">
                    <button className="sb-tooltip-trigger ml-2" type="button">
                      <Information />
                    </button>
                  </Tooltip>
                </div>
              </div>
            )}
            {showPasswordForm && (
              <div className="mb-6">
                <div className="flex justify-between items-center mt-4">
                  <span className="text-xs">Password</span>
                  <a
                    href="#"
                    className="text-xs"
                    style={{ color: "#0F62FE", textDecoration: "underline" }}
                  >
                    Forgot password?
                  </a>
                </div>
                <Layer>
                  <TextInput
                    size="lg"
                    labelText=""
                    autoComplete="true"
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    className="password-input"
                  />
                </Layer>
                {incorrectPassword && (
                  <p className="pt-2 text-red-500">Incorrect password</p>
                )}
                <div
                  className="flex items-center mt-4"
                  style={{ visibility: "hidden" }}
                >
                  <Checkbox
                    labelText={`Remember user ID`}
                    checked={true}
                    id="checkbox-label-1"
                  />
                  <Tooltip align="bottom">
                    <button className="sb-tooltip-trigger ml-2" type="button">
                      <Information />
                    </button>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-14 flex justify-between w-full">
          <Button
            kind="ghost"
            size="xl"
            style={{ width: "50%", maxInlineSize: "100%" }}
          >
            Single Sign On
          </Button>
          <Button
            kind="primary"
            type="submit"
            size="xl"
            renderIcon={ArrowRight}
            iconDescription={showPasswordForm ? "Sign In" : "Continue"}
            style={{ width: "50%", maxInlineSize: "100%" }}
          >
            {showPasswordForm ? "Sign In" : "Continue"}
          </Button>
        </div>
      </form>
    </div>
  );
}
